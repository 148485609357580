<template>
  <div>
    <div class="top">
        <div class="top_box">
            <h2>{{index.title}}</h2>
            <div class="path">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item >关于我们</el-breadcrumb-item>
                <el-breadcrumb-item >{{index.title}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
    </div>
    <div class="center">
        <div class="center_box">
            <div class="left">
                <div class="nav">
                    <ul>
                        <li v-for="(item,i) in list" :key="i" :class="item.id == index.id ? 'isaction':''" :data-id="item.id" @click="getinfo($event)" >{{item.name}} <i class="el-icon-caret-right"></i></li>
                    </ul>
                </div>
                <div class="introduction_title">
                    <h3>
                        看一看
                    </h3>
                    <div class="introduction_line">
                        <div class="introduction_line_em">
                        </div>
                    </div>
                </div>
                <div class="introduction">
                    {{index.introduction}}
                </div>
                <div class="call_us">
                    <h3>联系我们</h3>
                    <p>地址：<span>{{cpinfo.address}}</span></p>
                    <p>联系人：<span>{{cpinfo.contact_name}}</span></p>
                    <p>联系电话：<span>{{cpinfo.contact_tel}}</span></p>
                    <p>电子邮箱：<span>{{cpinfo.email}}</span></p>
                    <!-- <el-button class="" type="primary">留言</el-button> -->
                </div>
            </div>
            <div class="right">
                <div class="introduction_title" v-if="index.infotype == 2">
                    <h3>
                        {{index.title}}
                    </h3>
                    <div class="introduction_line">
                        <div class="introduction_line_em">
                        </div>
                    </div>
                </div>
                <div class="text" v-html="indexcontent" v-if="index.infotype == 1">
                    
                </div>
                <div class="list" v-if="index.infotype == 2">
                    <ul>
                        <li v-for="(item,key) in index.indexlist" :key="key" :data-key='key' @click="showcontent($event)">
                            <div class="new_left">
                                <div class="new_img" :style="{background: 'url('+config.URL+item.image+')',backgroundSize: '100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat'}"></div>
                                <div class="new_info">
                                    <h3 class="new_title">{{item.title}}</h3>
                                    <p class="new_text">{{item.text}}</p>
                                </div>
                            </div>
                            <div class="new_right">
                                <p>{{item.create_time}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div
                    class="block sc-pagination"
                    style="text-align: center; margin: 30px auto"
                    v-if="index.infotype == 2"
                >
                    <el-pagination
                    :current-page="requestData.currentPage"
                    :page-size="requestData.ListDatalength"
                    layout="sizes,total, prev, pager, next, jumper"
                    :total="count"
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    />
                </div>
                <div class="introduction_title introduction_marg">
                    <h3>
                        成功案例
                    </h3>
                    <div class="introduction_line">
                        <div class="introduction_line_em">
                        </div>
                    </div>
                </div>
                <div class="recommend">
                    <div class="recommend_box" v-for="(item,index) in index.successCase" :key="index" data-type="successCase" :data-classid="item.classId" :data-id="item.id" @click="jump">
                        <div class="recommend_main">
                            <div class="recommend_img" :style="{background: 'url('+config.URL+item.image+')',backgroundSize: '100%',backgroundPosition: 'center',backgroundRepeat: 'no-repeat'}">
                                <div class="recommend_info">
                                    <h3 class="recommend_title">{{item.title}}</h3>
                                    <p class="recommend_text">{{item.text}}</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="footer">
        <div class="footer_box">

        </div>
    </div>
  </div>
</template>

<script>
import  config  from "../../config";
import axios from "axios";
export default {
  name: 'About',
  data() {
    return {
        index: {
            id: 0,
            title: "",
            introduction: "",
            infotype: 1,
            successCase: [],
            indexlist : []
        },
        indexcontent: '',
        list: [],
        cpinfo: {},
        config: {
            URL: ''
        },
        count: 0,
        // 获取数据请求传参
        requestData: {
            // 每页条数
            ListDatalength: 10,
            // 当前页
            currentPage: 1
        },
    }
  },
  components: {
    
  },
  create(){
  },
  mounted() {
    this.config.URL = config.URL
    var classid = this.$route.query.classid
    this.getListData(classid)
  },
methods:{
    getinfo(e){
        var id = e.currentTarget.dataset.id
        this.getListData(id)
    },
    getListData(query_id,id){
        var ts = this
        var isshow = 0;
        this.$util.post('/user/serviceClassSelect').then(res => {
            try{
                res.list.forEach(item => {
                    console.log(item)
                    if(item.id == query_id || query_id == undefined){
                        if(query_id == undefined){
                            query_id = item.id
                        }
                        if(item.type == 2){
                            axios({
                                method: 'post',
                                data:{
                                    offset: (ts.requestData.currentPage-1) * ts.requestData.ListDatalength,
                                    length: ts.requestData.ListDatalength, 
                                    classId: query_id
                                },
                                url: config.URL+'/user/serviceSelect',
                            }).then(ress=>{
                                ress.data.data.forEach((items,index) =>{
                                    if(items.content != null){
                                        ress.data.data[index].text = items.content.replace(/<[^>]+>/g, '')
                                    }
                                    if(items.id == id){
                                        ts.indexcontent = items.content
                                        isshow = 1
                                    }
                                })
                                ts.count = ress.data.count
                                ts.index = {
                                    id: item.id,
                                    title: item.name,
                                    introduction: item.introduce,
                                    infotype: item.type,
                                    indexlist: ress.data.data
                                }

                                if(isshow){
                                    ts.index.infotype = 1
                                }
                            })
                        }else{
                            var successCase = item.successCase
                            successCase.forEach((item1,index1) => {
                                successCase[index1].text = item1.content.replace(/<[^>]+>/g, '')
                            })
                            ts.index = {
                                id: item.id,
                                title: item.name,
                                introduction: item.introduce,
                                infotype: item.type,
                                successCase: item.successCase,
                                indexlist: ""
                            }
                            ts.indexcontent = item.content
                            
                        }
                        
                        
                        throw new Error("ending");
                    }
                });
            }catch(e){
                if(e.message == "ending"){
                    console.log("结束了") ;
                }else{
                    console.log(e.message);
                }
            }
            ts.list = res.list
            ts.cpinfo = res.company_information 
        })
    },
    showcontent(e){
        var key = e.currentTarget.dataset.key
        this.indexcontent = this.index.indexlist[key].content
        console.log(this.indexcontent)
        this.index.infotype = 1
    },
     // 改變每頁顯示數據數量
    handleSizeChange (val) {
      this.requestData.ListDatalength = val
      var id = this.index.id
      this.getListData(id)
    },
    // 改變頁碼
    handleCurrentChange (val) {
      this.requestData.currentPage = val
      var id = this.index.id
      this.getListData(id)
    },
    jump(e){
        var type = e.currentTarget.dataset.type
        switch (type) {
            case 'successCase':
            var successCase_classid = e.currentTarget.dataset.classid
            var successCase_id = e.currentTarget.dataset.id
            this.$router.push({path:'/case',query:{classid:successCase_classid,id:successCase_id}})
            break;
        }
    }
}
}
</script>

<style scoped lang='scss'>
    .top{
        background: url('../../images/slogan.png');
        background-size: 100%;
        background-position: center;
        .top_box{
            width: 1200px;
            height: 200px;
            margin: 0 auto;
            text-align: center;
            line-height: 200px;
            color: #fff;
            h2{
                font-size: 30px;
            }
            .path{
                background: #fff;
                border: 1px solid #ddd;
                padding: 20px;
                position: relative;
                top: -28px;
                .el-breadcrumb{
                    font-size: 18px;
                }
            }
        }
    }
    .center{
        padding-top: 80px;
        color: #000;
        .center_box{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            .left{
                width: 300px;
                .nav{
                    width: 100%;
                    ul{
                        border: 1px solid #ddd;
                        border-bottom: none;
                        li{
                            display: flex;
                            justify-content:space-between;
                            padding: 10px 25px;
                            font-size: 18px;
                            border-bottom: 1px solid #ddd;
                            i{
                                transition: all 0.5s;
                            }
                        }
                        li:hover{
                            i{
                                transform: translateX(15px);
                                color: #1688d8;
                            }
                            
                            border-left: 3px solid #1688d8;
                        }
                        .isaction{
                            border-left: 5px solid #1688d8;
                            color: #1688d8;
                            i{
                                color: #1688d8;
                            }
                        }
                    }
                }
                .introduction_title{
                    margin-top: 40px;
                    h3{
                        color: #1688d8;
                        font-size: 24px;
                        font-weight: 500;
                    }
                    .introduction_line{
                        width: 100%;
                        height: 2px;
                        background: #999;
                        .introduction_line_em{
                            width: 100px;
                            height: 2px;
                            background: #1688d8;
                            
                        }
                    }
                }
                .introduction{
                    padding: 20px 10px;
                    text-indent:2em;
                    line-height: 28px;
                    background: #eee;
                    color: #333;
                }
                .call_us{
                    color: #fff;
                    padding:20px;
                    margin-top: 40px;
                    background: #1688d8;
                    h3{
                        font-size: 20px;
                        font-weight: 600;
                        margin-bottom: 20px;
                    }
                    p{
                        padding-bottom: 10px;
                        span{
                            color: #fff;
                        }
                    }
                    .el-button--primary{
                        margin-top: 30px;
                        background: #fff;
                        color: #1688d8;
                        width: 100%;
                    }
                }
            }
            .right{
                width: 870px;
                padding-left: 30px;
                .text{
                    width: 100%;
                    overflow: hidden;
                }
                .introduction_title{
                    
                    h3{
                        color: #1688d8;
                        font-size: 24px;
                        font-weight: 600;
                    }
                    .introduction_line{
                        width: 100%;
                        height: 4px;
                        background: #999;
                        .introduction_line_em{
                            width: 125px;
                            height: 4px;
                            background: #1688d8;
                            
                        }
                    }
                }

                .introduction_marg{
                    margin-top: 40px;
                }
                .recommend{
                    background: #eee;
                    display: flex;
                    width: 100%;
                    .recommend_box{
                        width: 250px;
                        height: 400px;
                        padding: 20px;
                        .recommend_main{
                            .recommend_img{
                                width: 100%;
                                width: 250px;
                                height: 360px;
                                position: relative;
                                .recommend_info{
                                    width: 100%;
                                    position: absolute;
                                    bottom: 0;
                                    height: 50px;
                                    background:rgba(0,0,0,0.6);
                                    .recommend_title{
                                        font-size: 20px;
                                        font-weight: 600;
                                        color: #222;
                                        color: #eee;
                                        text-indent: 1em;
                                    }
                                    .recommend_text{
                                        font-size: 14px;
                                        font-weight: 200;
                                        color: #eee;
                                        text-indent: 2em;
                                        overflow: hidden;
                                    }
                                    
                                }
                            }
                            
                            
                        }
                    }
                }

                .list{
                    ul{
                        li{
                            height: 90px;
                            border-bottom: 1px dashed #d4d0c8;
                            display: flex;
                            padding: 10px 5px;
                            position: relative;
                            .new_left{
                                width: 630px;
                                display: flex;
                                justify-content: space-between;
                                .new_img{
                                    width: 140px;
                                    height: 90px;
                                    background: url('../../images/illustration.jpg');
                                    background-size: 100%;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                }
                                .new_info{
                                    width: 470px;
                                    height: 80px;
                                    .new_title{

                                    }
                                    .new_text{
                                        margin-top: 5px;
                                        text-overflow: -o-ellipsis-lastline;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                        text-indent: 2em;
                                    }
                                }
                            }
                            .new_right{
                                width: 200px;
                                margin-left: 30px;
                                p{
                                    color: #999;
                                    position:absolute;
                                    bottom:15px;
                                    padding:0px;
                                    margin:0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .footer{
        .footer_box{
            padding:20px 0;
        }
    }
</style>

